$cp-content-max-width: 1024px;
$cp-white: white;
$cp-background: $cp-white;
$cp-brand-orange: #ff8c00;
$cp-back-brand-orange: #ffdfb8;
$cp-back-state-pink: #feeceb;
$cp-back-z-0-color: rgba(255, 255, 255, 0.4);
$cp-back-z-1-color: #f5f5f5;
$cp-back-z-2-color: #e2e2e2;
$cp-back-z-3-color: rgba(0, 0, 0, 0.04);
$cp-back-z-4-color: rgba(0, 0, 0, 0.4);
$cp-back-z-5-color: rgba(0, 0, 0, 0.2);

$cp-back-a-0-color: #969696;
$cp-back-a-1-color: #494949;
$cp-back-a-2-color: #1f1f1f;

$cp-back-b-0-color: #e1f5fe;

$cp-back-orange-color: #fff1e2;
/*
 * background hatching
 */
$cp-back-hatching-gray: repeating-linear-gradient(
  -45deg,
  #fff,
  #fff 5px,
  #f6f6f6 5px,
  #f6f6f6 10px
);

$cp-border-z-0-color: rgba(0, 0, 0, 0.2);

$cp-bs-yellow: #fff3cd; // bootstrap warning yellow background
$cp-bs-orage: #856404; // bootstrap warning yellow foreground

$cp-link-blue: #1a0dab;

$cp-x-red: #e53935;
$cp-y-green: forestgreen;
$cp-z-blue: royalblue;
$cp-col-dark-gray: #5f6368;
$cp-col-dark-blue: #01579b;
$cp-col-dark-blue2: #295ecd;
$cp-col-steel-gray: rgb(237, 235, 242);
$cp-col-lightest-blue: #eaf1fb;

$cp-ctrl-height-base-half: 50rem;
$cp-list-max-width: calc(100% - 24px);
$cp-center-content-max-width: 620px;
$cp-center-content-max-width-vw: 50vw;

$cp-disabled-opacity: 0.35;
$cp-dimmer-opacity: 0.5;
$cp-dimmer-light-opacity: 0.8;

$cp-zindex-normal: 0;
$cp-zindex-high: 1;
$cp-zindex-float: 2;
$cp-zindex-modal: 3;
$cp-zindex-overlay-pane-base: 1000;
$cp-zindex-overlay-pane: 1001;
$cp-zindex-overlay-pane-popup: 1003;
$cp-zindex-above-spinner: 100003;

$cp-rounded-sm: 4px;
$cp-rounded-md: 8px;
$cp-rounded-lg: 12px;

// $cp-highlight-background: $cp-blue-light;
// $cp-highlight-background-yellow: $cp-yellow-light;

$cp-img-muted: opacity(0.26);

/**
* font
*/
$cp-font-size-h1: 24px;
$cp-font-size-h2: 20px;
$cp-font-size-h3: 16px;
$cp-font-size-h4: 14px;
$cp-font-size-body: 13px;
$cp-font-size-caption1: 12px;
$cp-font-size-caption2: 10px;

$cp-line-height-lg: 32px;
$cp-line-height-md: 26px;
$cp-line-height-sm: 20px;
$cp-line-height-xs: 14px;

/**
* spacer
*/
$cp-spacer-1: 0.25rem;
$cp-spacer-2: 0.5rem;
$cp-spacer-3: 0.75rem;
$cp-spacer-4: 1rem;
$cp-spacer-5: 1.25rem;

//TODO: import bootstrap $font-weight-bold
$cp-font-weight-light: 300;
$cp-font-weight-bold: 700;

$cp-text-muted: rgba(0, 0, 0, 0.26);
$cp-text-white-muted: rgba(255, 255, 255, 0.26);

/**
* button
*/
$btn-pressed-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.4);

$cp-btn-min-width: 120px;
$cp-scroll-thumb-size: 8px;
$cp-scroll-thumb-size-touch-screen: 12px;
$cp-width-small-px: 80px;
$cp-width-mid-px: 120px;
$cp-width-large-px: 240px;
$cp-search-bar-max-width: 930px;
$cp-search-bar-height: 46px;

$cp-search-bar-infix-padding: 0.7em 0 0.7em 0;

/**
* State
*/
$cp-state-string: #818181;
$cp-success: #66bb6a;
$cp-processing: #f5f5f5;
$cp-error: #e53935;
$cp-warn: #ff8c00;
$cp-undefined: #f5f5f5;

/** tnumbnail images
*
*/
$cp-thumb-height-sm: 48px;
$cp-thumb-height-md: 60px;
$cp-thumb-height-lg: 80px;
$cp-thumb-height-xl: 140px;

/*
 * Dimensions
 */
$cp-side-width: 246px;
$cp-side-width-sm: 180px;
$cp-side-wide-width: 320px;
$cp-side-wide-width-sm: 300px;
$cp-side-wide-width-lg: 420px;
$cp-side-dashboard-width: 350px;

/*
 * image dimensions
 */
$cp-img-height-sm: 24px;

$cp-sign-card-width: 480px;

$cp-sign-card-width-ext: 600px;

$cp-user-avatar-panel-width: 230px;

//
// Obsolete - don't use this variables
//
$dg_0: #050505;
$dg_1: #161616;
$dg_2: #1f1f1f;
$dg_3: #272727;
$dg_4: #303030;
$dg_5: #383838;
$dg_6: #494949;
$dg_7: #414141;
$dg_8: #747474;
$g_1: #7c7c7c;
$g_2: #969696;
$g_3: #9e9e9e;
$g_4: #a6a6a6;
$g_5: #b7b7b7;
$g_6: #c8c8c8;
$w_1: #d9d9d9;
$w_2: #e2e2e2;
$w_3: #f5f5f5;
$w_4: #fbfbfb;
$w_5: #ffffff;
$b_1: #2196f3;
$b_2: #118ef2;
$b_3: #1976d2;
$r_1: #f55246;
$r_2: #f44336;
$n_1_1: #81c784;
$n_1: #62bb66;
$n_2: #4caf50;
$y_1: #fff59d;
$cupixColor: #ff9436;
$lightslategray: #778899;
$backGroundColor: $dg_3;
$cpDark: $dg_1;
$cpSideMenuBG: $dg_2;
$cpSideMenuTextHover: $g_6;
$cpSideMenuTextActive: $w_4;
$cpSideMenuWidth: 205px;
$searchbarInputFontSize: 14px;
$cpBgSearchBar: $dg_5;
$cpBgSearchInput: $g_6;
$paginatorTextColor: $g_2;
$paginatorSelectedTextColor: $g_5;
$paginatorHoverColor: $dg_6;
$activeCaptureCardIcon: #ff8c00;
$activeCaptureCardIconHover: #cc8822;
$inactiveCaptureCardIcon: $g_1;
$inactiveCaptureCardIconHover: $dg_8;
$activeSearchFilterIcon: #ff9436;
$activeSearchFilterIconHover: #cc8822;
$inactiveSearchFilterIcon: $g_1;
$inactiveSearchFilterIconHover: $w_1;
$captureCardDescription-SplitterColor: $dg_6;
$btnBlueColor: $b_1;
$captureCardDateColor: $w_2;
$captureCardTitleColor: $w_4;
$topBarHeight: 100px;
$topBarUpHeight: 64px;
$tabbarActiveBG: $b_2;
$tabbarActiveFG: $w_4;
$tabbarMenuLine: $dg_6;
$cardBackgroundColor: $dg_5;
$settingTextColor: $w_4;
$mdInputBoaderColor: $dg_6;
$mdInputTextColor: #cdcdcd;
$mdInputFocusTextColor: $w_4;
$addCaptureTitleBorder: $dg_6;
$addCaptureTitleText: $w_4;
$addSectionBG: $dg_5;
$dropdownlistTextColor: $w_4;
$dropdownlistBG: #1f1f1f;
$dropdownlistSelectBG: $dg_4;
$dropdownlistHoverBG: $dg_6;
$addSectiocButtonColor: $w_1;
$addSectiocButtonTextColor: $dg_4;
$dialogBG: #ffffff;
$dialogButtonBG: #e2e2e2;
$dialogButtonHoverBG: #4993eb;
$spinnerColor: #ff9436;
$spinner-default-color: #ff8213;
$cardContentTextColor: $g_4;
$button-color-white: $w_4;
$button-color-dark: $dg_4;
$buttonColorCupix: #ff9436;
$buttonColorCupixHorver: #ff872e;
$buttonColorLinkedin: #007bb5;
$buttonColorLinkedinHorver: #0073a0;
$buttonColorFacebook: #3e5094;
$buttonColorFacebookHorver: #2e4b7f;
$buttonColorBlue: $b_1;
$buttonColorBlueHorver: $b_2;
$buttonColorGreen: $n_1;
$buttonColorGreenHorver: $n_2;
$buttonColorRed: $r_1;
$buttonColorRedHorver: $r_2;
$buttonColorYellow: #fbc02d;
$buttonColorYellowHorver: #f9a825;
$buttonColorGray: $w_2;
$buttonColorGrayHorver: $w_5;
$buttonColorDarkGray: $dg_5;
$buttonColorDarkGrayHorver: $dg_6;
$tooltipBackgroundColor: #e2e2e2;
$tooltipForegroundColor: #303030;
$videoColor: $y_1;

$iconFontSize: 24px;
$bottom-bar-height: 32px;
$exec-view-init-mapbox-height: 275px;
$exec-view-init-mapbox-header-height: 30px;
$exec-view-init-mapbox-margin-top: $bottom-bar-height +
  $exec-view-init-mapbox-height + $exec-view-init-mapbox-header-height;
$sv-edit-init-mapbox-margin-top: $exec-view-init-mapbox-margin-top + 142px;
