.vertical-divider {
  border-right: 1px solid #dee2e6 !important;
  height: unset;
}

.horizontal-divider {
  width: 100%;
  border-top: 1px solid #dee2e6 !important;
  margin: 0.75rem 0 !important;
}

.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
