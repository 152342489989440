@import "cupix-variables.scss";

/**
* general
*/

html,
body {
  width: 100%;
  height: 100%;
}

// body {
//   @include reset-margin;
//   @include user-select-none;
// }

.transform-origin-left {
  transform-origin: left !important;
}

/**
* back-ground-color
*/

.cp-background {
  background-color: var(--cp-background) !important;
}

.cp-sub-background {
  background-color: var(--cp-sub-background) !important;
}

.cp-dark-background {
  background-color: var(--cp-dark-background) !important;
}

.cp-dark-sub-background {
  background-color: var(--cp-dark-sub-background) !important;
}

.cp-background-warning {
  background-color: var(--cp-background-warn) !important;
}

/**
* border-color
*/

.cp-border-color {
  border-color: var(--cp-border-color) !important;
}

.cp-border-caption {
  border-color: var(--cp-text-color-gray) !important;
}

.cp-border-hint {
  border-color: var(--cp-text-color-gray) !important;
}

.cp-border-reverse {
  border-color: var(--cp-text-color-reverse) !important;
}

.cp-border-transparent {
  border-color: transparent !important;
}

/**
* border-radius
*/

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/**
* border-width
*/

.cp-border-thickest {
  border-width: 3px !important;
}

.cp-border-thick {
  border-width: 2px !important;
}

.cp-border-normal {
  border-width: 1px !important;
}

/**
* hover
*/

.cp-dimmer-light-hover {
  opacity: $cp-dimmer-light-opacity;

  &:hover {
    opacity: 1;
  }
}

.cp-list-item-hover {
  background: $cp-back-z-3-color;
  // box-shadow: 1px 1px 3px rgba(0,0,0,0.25), 1px -1px 3px rgba(0,0,0,0.25);
}

// temp code - should be a directive
// extenstion of libCpHoverShadow
.cp-elevation-on-hover-z2 {
  &:hover {
    box-shadow:
      0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%),
      0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
}

/**
* font-color
*/

.cp-text-color {
  color: var(--cp-text-color);
}

.cp-text-color-caption {
  color: var(--cp-text-color-gray);
}

.cp-text-color-hint {
  color: var(--cp-text-color-gray);
}

.cp-text-color-reverse {
  color: var(--cp-text-color-reverse);
}

.cp-text-color-reverse-caption {
  color: var(--cp-text-color-reverse-caption);
}

.cp-text-color-primary {
  color: var(--cp-color-primary);
}

.cp-text-color-brand {
  color: var(--cp-text-color-brand);
}

.cp-text-white {
  color: var(--cp-color-white);
}

.cp-text-warn {
  color: var(--cp-background-warn);
}

.cp-color-link {
  color: var(--cp-color-primary);
}

/**
* font-size
*/

.cp-text-h1 {
  font-size: $cp-font-size-h1;
}

.cp-text-h2 {
  font-size: $cp-font-size-h2;
  line-height: $cp-line-height-md;
}

.cp-text-h3 {
  font-size: $cp-font-size-h3;
}

.cp-text-h4 {
  font-size: $cp-font-size-h4;
}

.cp-text-body {
  font-size: $cp-font-size-body;
}

.cp-text-bold {
  font-weight: $cp-font-weight-bold;
}

.cp-text-caption1 {
  font-size: $cp-font-size-caption1 !important;
}

.cp-text-caption2 {
  font-size: $cp-font-size-caption2 !important;
}

.cp-text-muted {
  color: $cp-text-muted !important;
}

.cp-text-white-muted {
  color: $cp-text-white-muted !important;
}

.cp-sidenav-shadow {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25) !important;
}

.cp-sidenav-shadow-right {
  -webkit-box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.25) !important;
}

.cp-sidenav-shadow-left {
  -webkit-box-shadow: -10px 0 10px -10px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: -10px 0 10px -10px rgba(0, 0, 0, 0.25) !important;
  box-shadow: -10px 0 10px -10px rgba(0, 0, 0, 0.25) !important;
}

/**
* trancate
*/

.cp-text-truncate-line-3 {
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
}

.width-max-content {
  width: max-content;
}

/**
* event
*/

.block-pointer-event {
  pointer-events: none;
}

/**
* cursor type
*/
.cp-cursor-all-scroll {
  cursor: all-scroll;
}

.cp-cursor-auto {
  cursor: auto;
}

.cp-cursor-cell {
  cursor: cell;
}

.cp-cursor-col-resize {
  cursor: col-resize;
}

.cp-cursor-crosshair {
  cursor: crosshair;
}

.cp-cursor-default {
  cursor: default;
}

.cp-cursor-e-resize {
  cursor: e-resize;
}

.cp-cursor-ew-resize {
  cursor: ew-resize;
}

.cp-cursor-grab {
  cursor: grab;
}

.cp-cursor-grabbing {
  cursor: grabbing;
}

.cp-cursor-help {
  cursor: help;
}

.cp-cursor-move {
  cursor: move;
}

.cp-cursor-n-resize {
  cursor: n-resize;
}

.cp-cursor-ne-resize {
  cursor: ne-resize;
}

.cp-cursor-nesw-resize {
  cursor: nesw-resize;
}

.cp-cursor-ns-resize {
  cursor: ns-resize;
}

.cp-cursor-nw-resize {
  cursor: nw-resize;
}

.cp-cursor-nwse-resize {
  cursor: nwse-resize;
}

.cp-cursor-no-drop {
  cursor: no-drop;
}

.cp-cursor-none {
  cursor: none;
}

.cp-cursor-not-allowed {
  cursor: not-allowed;
}

.cp-cursor-pointer {
  cursor: pointer;
}

.cp-cursor-row-resize {
  cursor: row-resize;
}

.cp-cursor-s-resize {
  cursor: s-resize;
}

.cp-cursor-se-resize {
  cursor: se-resize;
}

.cp-cursor-sw-resize {
  cursor: sw-resize;
}

.cp-cursor-text {
  cursor: text;
}

.cp-cursor-w-resize {
  cursor: w-resize;
}

.cp-cursor-zoom-in {
  cursor: zoom-in;
}

.cp-cursor-zoom-out {
  cursor: zoom-out;
}

/**
* panel
*/

.auto-sizing-panel {
  max-height: 50vh;
  overflow: auto;
}

.cp-panel-view-selector-selected {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #1e88e5 !important;
}

/**
* Icon
*/

// Temp code: needs refactoring
$pressed-background-color: rgba(0, 0, 0, 0.1);

// .cp-pushpin-pressed {
//   box-shadow: $btn-pressed-shadow;
//   background-color: $pressed-background-color;
//   border-radius: 50%;
//   color: $cp-primary;
// }

.cp-circ-icon {
  button {
    background-color: unset;
  }

  lib-cp-icon {
    margin-top: 6px !important;

    mat-icon,
    i {
      background-color: var(--cp-background);
      border-radius: 50%;
      border: 1px solid var(--cp-color-primary);
      padding: 6px !important;
      color: var(--cp-color-primary);

      &.cp-text-muted {
        border: 1px solid $cp-text-muted !important;
      }
    }
  }

  span {
    margin: -5px 0 !important;
  }
}

.cp-circ-icon-solid {
  button {
    background-color: unset;
  }

  lib-cp-icon {
    margin-top: 6px !important;

    mat-icon,
    i {
      background-color: var(--cp-color-primary);
      border-radius: 50%;
      border: 1px solid var(--cp-background);
      padding: 6px !important;
      color: var(--cp-background) !important;

      &.cp-text-muted {
        background-color: rgba(0, 0, 0, 0.26); // referene angular
      }
    }
  }

  span {
    margin: -5px 0 !important;
  }
}

.cp-img-icon-muted {
  filter: $cp-img-muted !important;
}

.cp-compact-state-icon {
  transform: scale(0.75, 0.75) !important;
  padding: unset !important;
}

/**
* Tooltip
*/
.cp-tooltip,
.cp-tooltip-sm-margin {
  color: #fff;
  border-radius: 4px;
  max-width: 250px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cp-tooltip {
  margin: 14px !important;
}

.cp-tooltip-sm-margin {
  margin: 2px !important;
}

.cp-property-group-indent {
  lib-cp-item:not(.cp-property-item-title):not(.cp-property-item-no-indent) {
    padding-left: 1em;
  }
}

.cp-drop-down-file {
  .ngx-file-drop__drop-zone {
    position: relative;
    height: unset !important;
    border: unset !important;
    border-radius: unset !important;

    .ngx-file-drop__content {
      display: block !important;
      height: unset !important;
      align-items: unset !important;
      justify-content: unset !important;
      color: unset !important;
    }
  }
}

.cp-drop-down-file-hovered {
  .ngx-file-drop__drop-zone {
    background-color: #0782d070 !important;
    opacity: 0.5;
  }
}

lib-cp-toolbar {
  lib-cp-menu-item {
    &.cp-dropdown-arrow {
      padding: 0 4px;

      button::after {
        content: "\25BE";
        //TODO: color should be matched to the icon color
        // color: white;
      }
    }
  }
}

/**
* Permission MemberList
*/

.cp-permission-member-list {
  max-height: 310px;
  overflow-y: auto;
}

.cp-permission-member-list.mobile {
  max-height: none;
}

// cp-hint
.cp-hint {
  flex-grow: 1;
  flex-shrink: 1;
  letter-spacing: 0.01428571em;
  font-family: Roboto, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  word-break: break-word;
  word-wrap: break-word;
  color: $cp-col-dark-gray;
  white-space: pre-line;
  margin: 0;
  padding-top: 8px;
}

.cp-hint-box {
  background: var(--cp-sub-background) !important;
  padding: 1rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
}

cw-hint[small] {
  .cp-hint {
    line-height: 1rem;
    font-size: 0.75rem;
  }
}

cw-hint[box] {
  .cp-hint {
    @extend .cp-hint-box;

    &.tip,
    &.note,
    &.error,
    &.required {
      padding-left: 52px !important;

      &::before {
        position: absolute;
        left: 16px;
        font-size: 24px;
      }
    }

    &.tip::before {
      font-family: "Cupix MX Icons";
      content: "0";
      color: green;
    }

    &.note::before {
      font-family: "Material Icons Outlined";
      content: "school";
      color: green;
    }

    &.error::before {
      font-family: "Material Icons Outlined";
      content: "priority_high";
      color: darkorange;
    }

    &.required::before {
      font-family: "Material Icons Outlined";
      content: "priority_high";
      color: darkorange;
    }

    &.checked::before {
      font-family: "Material Icons Outlined";
      content: "check_circle";
      color: green;
    }
  }
}

cw-hint[box][white] {
  .cp-hint {
    background: $cp-white !important;
  }
}

cw-hint[box][highlight] {
  .cp-hint {
    background: $cp-back-b-0-color !important;
    color: $cp-col-dark-blue;
  }
}

/**
* Dialog
*/
lib-cp-dialog-description {
  color: $cp-col-dark-gray;
}

mat-dialog-container {
  height: inherit !important;

  lib-cp-dialog {
    height: 100%;
  }
}

/* Fixed Table */
.cp-table-fixed {
  table-layout: fixed;
  width: 100%;
}

.cp-table-truncate {
  white-space: nowrap;

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}