#root {
  height: 100%;
}

@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "assets/styles/cupix-variables.scss";
@import "assets/styles/responsive.scss";
@import "assets/styles/custom.scss";

// for test
@import "assets/styles/global-custom.scss";
