/* availabe breakpoints 

@include media-breakpoint-down(xs)  576px
@include media-breakpoint-down(sm)  768px
@include media-breakpoint-down(md)  992px
@include media-breakpoint-down(lg)  1200px

*/
.cp-resp-side {
  width: $cp-side-width;

  @include media-breakpoint-down(lg) {
    width: $cp-side-width-sm;
  }
}

.cp-resp-side-wide {
  width: $cp-side-wide-width;

  @include media-breakpoint-down(lg) {
    width: $cp-side-wide-width-sm;
  }
}

.cp-resp-sign-card {
  width: $cp-sign-card-width;

  @include media-breakpoint-down(sm) {
    width: 85vw;
  }
}

.cp-resp-sign-card-ext {
  width: $cp-sign-card-width-ext;

  @include media-breakpoint-down(sm) {
    width: 85vw;
  }
}

.cp-resp-w-100-sm {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.cp-resp-hide-sm {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.cp-resp-hide-md {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.cp-resp-text-h2 {
  font-size: $cp-font-size-h2;

  @include media-breakpoint-down(lg) {
    font-size: $cp-font-size-h4;
  }
}

.cp-resp-display-lg {
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.cp-resp-display-md {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.cp-resp-display-pt-1 {
  @include media-breakpoint-down(lg) {
    padding-top: 0.25rem;
  }
}
