//
// Override library (Cupix UI, Bootstrap, Material, toastr) variables & classes
//

$control-height: 40px;
$control-mid-height: 32px;
$control-sm-height: 24px;
$border-radius: 5px;
$border-color: #dee2e6 !default;
$spacer: 1rem !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$icon-prefix-mid: 16px;
$icon-prefix-sm: 16px;

.cp-icon-prefix-md {
  mat-icon {
    margin-right: 0 !important;
    margin-left: 0 !important;
    font-size: $icon-prefix-mid;
    width: $icon-prefix-mid;
    height: $icon-prefix-mid;
  }

  .icon-size {
    width: $icon-prefix-mid;
    height: $icon-prefix-mid;
  }
}

.cp-icon-prefix-sm {
  mat-icon {
    margin-right: 0 !important;
    margin-left: 0 !important;
    font-size: $icon-prefix-sm;
    width: $icon-prefix-sm;
    height: $icon-prefix-sm;
    padding: 0 !important;
  }

  .icon-size {
    width: $icon-prefix-sm;
    height: $icon-prefix-sm;
  }
}

.cp-badge-icon-postfix-smallest {
  display: inline-block;
  vertical-align: middle;

  .cp-image-icon {
    width: auto !important;
    height: 14px !important;
  }
}

.cp-badge-icon-postfix-compact {
  display: inline-block;
  vertical-align: middle;

  .cp-image-icon {
    width: auto !important;
    height: 20px !important;
  }
}

.cp-badge-icon-postfix {
  display: inline-block;
  vertical-align: middle;

  .cp-image-icon {
    width: auto !important;
    height: 24px !important;
  }
}

cp-review-tree {
  mat-tree {
    padding: ($spacer * 0.5) ($spacer * 1) ($spacer * 0.5) ($spacer * 1);
  }
}

// Hardcoding for scrollbar
::-webkit-scrollbar {
  width: $cp-scroll-thumb-size;
  height: $cp-scroll-thumb-size;
}

.touch-screen {
  ::-webkit-scrollbar {
    width: $cp-scroll-thumb-size-touch-screen;
    height: $cp-scroll-thumb-size-touch-screen;
  }
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow:
    inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  -webkit-box-shadow:
    inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}

::-webkit-scrollbar-track {
  background-color: $cp-back-z-2-color;
}

// Override gutter
.as-split-gutter {
  //background-color: rgba(0, 0, 0, 0.8) !important;
}

.as-vertical,
.as-horizontal > .as-split-gutter {
  .as-split-gutter-icon {
    position: absolute;
    border-radius: $border-radius;
    background-image: initial !important;
    background-color: black;
    transition: opacity 0.3s;
    opacity: 0;
  }

  .as-split-gutter.as-dragged .as-split-gutter-icon,
  .as-split-gutter-icon:hover {
    opacity: 0.1;
  }
}

.as-vertical > .as-split-gutter {
  .as-split-gutter-icon {
    width: 100% !important;
    height: 14px !important;
  }
}

.as-horizontal > .as-split-gutter {
  .as-split-gutter-icon {
    height: 100% !important;
    width: 14px !important;
  }
}

//
// Extending Bootstrap/Material and supplementing missing classes
//
.w-0 {
  width: 0;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.h-0 {
  height: 0 !important;
}

.h-90 {
  height: 90%;
}

.h-85 {
  height: 85%;
}

.h-60 {
  height: 60%;
}

.h-unset {
  height: unset !important;
}

.mx-25 {
  max-width: 25%;
}

.mt--2 {
  margin-top: -1rem;
}

.d-contents {
  display: contents;
}

.w-fit-content {
  width: fit-content;
}

.border-dark {
  border: 1px solid #adb5bd !important;
}

.text-decoration-underline {
  text-decoration-line: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.p-absolute-left-center {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.p-absolute-right {
  position: absolute;
  right: 0;
}

.p-absolute-right-center {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.p-absolute-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.p-absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.p-absolute-top-center {
  position: absolute;
  top: 0;
  right: 50%;
  transform: translateX(50%);
}

.p-absolute-top {
  position: absolute;
  top: 0;
}

.p-absolute-bottom {
  position: absolute;
  bottom: 0;
}

.p-absolute-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.p-absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.p-absolute-bottom-center {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.p-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-relative {
  position: relative !important;
}

.p-fixed-top-right {
  position: fixed;
  top: 0;
  right: 0;
}

.p-fixed-top-left {
  position: fixed;
  top: 0;
  left: 0;
}

.p-fixed-bottom-right {
  position: fixed;
  bottom: 0;
  right: 0;
}

.p-fixed-bottom-left {
  position: fixed;
  bottom: 0;
  left: 0;
}

// @each $size,
// $length in $spacers {
//   .gap-#{$size} {
//     gap: $length;
//   }
// }

.d-flex {
  display: flex;
}

.d-flex-column {
  @extend .d-flex, .flex-column;
}

.d-flex-row {
  @extend .d-flex, .flex-row;
}

.d-flex-row-reverse {
  @extend .d-flex, .flex-row-reverse;
}

.d-flex-column-reverse {
  @extend .d-flex, .flex-column-reverse;
}

.object-fit-contain {
  object-fit: contain;
}

.align-center {
  @extend .align-items-center, .justify-content-center;
}

// row, align center
.d-flex-align-center {
  @extend .d-flex, .align-items-center;
}

// row, content center (left & right margins are the same)
.d-flex-content-center {
  @extend .d-flex, .justify-content-center;
}

// row, content & items center
.d-flex-center {
  @extend .d-flex, .align-items-center, .justify-content-center;
}

// column, content center (top & bottom margins are the same)
.d-flex-column-content-center {
  @extend .d-flex, .flex-column, .justify-content-center;
}

// column, content & items center
.d-flex-column-center {
  @extend .d-flex, .flex-column, .align-items-center, .justify-content-center;
}

.align-items-flex-start {
  align-items: flex-start;
}

.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.card {
  @extend .text-center, .border;

  .card-header {
    @extend .d-flex, .justify-content-center, .pt-2;

    .card-title {
      @extend .pt-3, .cp-text-h1;
    }
  }

  .card-content {
    @extend .px-3, .py-3;

    .card-row {
      @extend .pt-3, .mx-auto;
    }
  }
}

// Bootstrap default alert css
.badge {
  border-radius: 0.25rem;
  justify-content: center;
  font-size: 80%;
  padding: 0.25rem 0.5rem;
  max-width: 130px;

  &.success {
    background: $cp-success;
    color: $cp-white;
  }

  &.demo {
    border-radius: 1rem;
    background: $cp-col-dark-blue;
    color: $cp-white;
  }

  &.deactivated {
    border-radius: 1rem;
    background: $cp-brand-orange;
    color: $cp-white;
  }

  &.locked {
    border-radius: 1rem;
    background: $cp-x-red;
    color: $cp-white;
  }

  &.draft {
    border-radius: 1rem;
    background: $tooltipBackgroundColor;
    color: $dg_7;
  }

  &.essentials {
    color: $cp-white;
    background-color: $cp-x-red;
  }

  &.builders {
    color: $cp-white;
    background-color: $cp-col-dark-gray;
  }

  &.unified {
    color: $cp-white;
    background-color: $cp-col-dark-blue;
  }

  &.si {
    color: $cp-white;
    background-color: $cp-back-a-2-color;
  }

  &.project {
    color: $cp-white;
    background-color: $cp-col-dark-blue;
    font-weight: $font-weight-medium;
  }

  &.pending {
    color: $cp-back-z-4-color;
    background-color: $cp-white;
  }

  &.rejected {
    color: $r_2;
    background-color: $cp-back-state-pink;
  }
}

.empahsis {
  font-weight: $font-weight-bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.essentials {
  @extend .empahsis;
  color: $cp-x-red;
}

.builders,
.pilot,
.workspace {
  @extend .empahsis;
  color: $cp-col-dark-gray;
}

.unified {
  @extend .empahsis;
  color: $cp-col-dark-blue;
}

.draft {
  color: $g_1;
}

.si {
  color: $cp-back-a-2-color;
  font-weight: $font-weight-medium;
}

td {
  .nopadding.badge {
    padding: 0 !important;
  }
}

.badge-circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  justify-content: center;
  font-size: 80%;
  margin: 0.5rem;
}

.success-text-color {
  color: $cp-success;
}

.processing-text-color {
  color: $cp-processing;
}

.error-text-color {
  color: $cp-error;
}

.undefined-text-color {
  color: $cp-state-string;
}

.success {
  color: $cp-white;
  background-color: $cp-success;
}

.processing {
  color: $cp-state-string;
  background-color: $cp-processing;
}

.error {
  color: $cp-white;
  background-color: $cp-error;
}

.warn {
  color: $cp-white;
  background-color: $cp-warn;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
}

.undefined {
  color: $cp-state-string;
  border: solid 1px $cp-undefined;
}

.undefined-fill {
  color: $cp-state-string;
  background-color: $cp-undefined;
}

// cdk drag and drop
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: $border-radius;
  background-color: white;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging :not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// Mapbox
.mapboxgl-ctrl-logo {
  display: none !important;
}

// google-map
.map-container {
  height: calc(100vh - 64px);
  width: 100%;
}

//gridster
gridster-item {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;

  .gridster-item-resizable-handler {
    &.handle-s {
      height: 7px;
    }

    &.handle-e {
      width: 10px;
    }

    &.handle-n {
      height: 5px; // top bar 선택이 잘 안됨
    }

    &.handle-w {
      width: 10px;
    }

    &.handle-se {
      height: 20px;
      width: 20px;
      // css triangle trick
      border-width: 0 0 20px 20px;
      right: -20px;
    }

    &.handle-ne {
      height: 10px;
      width: 10px;
    }

    &.handle-sw {
      height: 20px;
      width: 20px;
    }

    &.handle-nw {
      height: 20px;
      width: 20px;
    }
  }

  &.mobile {
    .gridster-item-resizable-handler {
      &.handle-s,
      &.handle-n {
        height: 32px;
      }

      &.handle-e,
      &.handle-w {
        width: 32px;
      }
    }
  }
}

.cp-image-height {
  height: 200px;
}

.locked-badge {
  height: 20px;
  width: 70px;
  border-radius: 15px;
  border: solid 1px #e53935;
  background-color: #ffd9d8;
  display: flex;
  align-items: center;
  padding: 2px;
  justify-content: center;
  margin-left: 6px;

  span {
    font-size: 12px;
    font-weight: 500;
    color: #e53935;
  }
}

.half-control {
  width: 50%;
  box-sizing: border-box;
}

.blue-rounded-medium-button {
  border-radius: 5px !important;
  background-color: #1e88e5;
  color: $cp-white;
  width: 120px;
}
